import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import { BookMeeting } from "../../components/BookMeeting";
import { Container } from "../../components/Container";

const content = css`
  ${tw`sm:max-w-3xl mx-1 sm:mx-auto text-center`}
  p {
    ${tw`mt-6`}
  }
`;

const Banner: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <Container {...rest}>
      <div>
        <section css={content}>
          <h2>{t("index.satisfyYourCuriosity.header")}</h2>
          <BookMeeting skipComment={true} />
          {/* <p>{t("index.satisfyYourCuriosity.itsFree")}</p> */}
        </section>
      </div>
    </Container>
  );
};

export default Banner;
